import React, {useEffect, useState} from "react";
import "./style.css";
import TeacherCard from "../../Cards/TeacherCard/TeacherCard";
import Tilt from "react-parallax-tilt";
import Typo from "./../../../utils/Typo/Typo";
import SectionTitle from "../../../utils/SectionTitle/SectionTitle";
import {teachers} from "../../../data/teachersData";
import {useMediaQuery} from "../../../customHooks/useMediaQueries";
import {useNavigate} from "react-router-dom";
import Tooltip from "./../../../utils/Tooltip/Tooltip";
import {Player} from "@lottiefiles/react-lottie-player";
import lottie from "../../../assets/lotties/Animation - 1725796118810.json";
import axios from "axios";
import {BASE_URL} from "../../../API/BASE_URL";
import toast from "react-hot-toast";
import Spinner from "../../../utils/Spinner/Spinner";
import EmptyContent from "../../../utils/EmptyContent/EmptyContent";
const TeachersSection = () => {
  const smallScreens = useMediaQuery("(max-width:768px)");
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [teachersData, setTeachersData] = useState([]);
  const getTeachersData = async () => {
    setLoading(true);

    await axios
      .get(`${BASE_URL}/info/select_teacher.php`)
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setTeachersData(res?.data?.message);
        } else {
          toast.error("There is a problem in teachers section!");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getTeachersData();
  }, []);

  return (
    <div data-aos='zoom-in' className='teacher_section container'>
      <div className='lottie_animation'>
        <Player
          autoplay
          loop
          src={lottie} // You can also use a URL here, like 'https://assets.lottiefiles.com/packages/lf20_yourfile.json'
          style={{transform: "scale(1.4)"}}
        />
        <Player
          autoplay
          loop
          src={lottie} // You can also use a URL here, like 'https://assets.lottiefiles.com/packages/lf20_yourfile.json'
          style={{transform: "scale(1.4)"}}
        />
      </div>

      <SectionTitle
        color={"#4E2F7D"}
        my={"30px"}
        fw={"bolder"}
        variant={smallScreens ? "h4" : "h1"}
        title={"أبرز المدرسين"}
        showMore={true}
        onClick={() => navigate("all-teachers")}
        showMoreText={"عرض كل المدرسين"}
      />

      {loading ? (
        <Spinner variant={"io"} />
      ) : teachersData &&
        teachersData.length >= 1 &&
        Array.isArray(teachersData) ? (
        <div className='teacher_section_content'>
          {teachersData?.slice(0, 6)?.map((item, index) => {
            return (
              <Tilt className='w-100' key={index} data={item}>
                <Tooltip
                hide={!item?.nick_name}
                  className={"w-100"}
                  bg='#2f1d33'
                  position='top'
                  title={
                    <Typo variant={"h5"} color={"#FFF"}>
                      {item?.nick_name}#
                    </Typo>
                  }
                >
                  <TeacherCard
                    onClick={(e) => {
                      e.stopPropagation();

                      navigate(`/teacher/${item?.teacher_id}`, {
                        state: {data: item},
                      });
                    }}
                    data={item}
                  />
                </Tooltip>
              </Tilt>
            );
          })}
        </div>
      ) : (

        ""
        // <EmptyContent size={"large"} text={"لا يوجد مدرسين إلى الآن!"} />
      )}
    </div>
  );
};

export default TeachersSection;
