import React from "react";
import "./style.css";
import EmptyContent from "./../../../../../utils/EmptyContent/EmptyContent";
const TimeLineContent = ({data}) => {
  console.log(data);
  return (
    <div className='time_line_content'>
      <div className='container'>
        {data &&
        data?.months &&
        data.months.length >= 1 &&
        Array.isArray(data?.months) ? (
          <div className='timeline'>
            <div className='timeline-section'>
              {data?.months?.map((item, index) => {
                return (
                  <>
                    <div key={index} className='timeline-section'>
                      <div className='timeline-date'>
                        {item?.month} {data.year}
                      </div>
                      {item &&
                      item?.structure &&
                      item?.structure?.length >= 1 ? (
                        <div className='row gy-3 gy-3'>
                          {item?.structure?.map((topic, index) => {
                            return (
                              <div
                                key={index}
                                className=' col-12 col-sm-12 col-md-6 col-lg-4'
                              >
                                <div className='timeline-box'>
                                  <div className='box-title'>{topic.title}</div>
                                  <div className='box-content'>
                                    {topic?.description}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <EmptyContent
                          size={"small"}
                          text={"  لا يوجد  خطة زمنية لهذا الشهر"}
                          typoProps={{variant: "h6", py: "20px"}}
                        />
                      )}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        ) : (
          <EmptyContent
            size={"large"}
            text={"  لا يوجد  خطة زمنية لهذا العام"}
          />
        )}
      </div>
    </div>
  );
};

export default TimeLineContent;
