import React, { useState } from "react";
import "./style.css";
import img from "../../../assets/images/errorStudent.jpg";
import Typo from "../../../utils/Typo/Typo";
import {star_medal} from "../../../assets/svgIcons";
import Modal from "../../../utils/Modal/Modal";
const StudentCard = ({data}) => {

  const handleError= (event)=>{
    event.target.src = img
  }




  return (
    <div className='best_std_card shado'>
      <div className='std_card_content'>
        <div className='std_image'>
          <img onError={handleError} src={data.image} alt='' />
          <div className='medal_icon'>{star_medal}</div>
          {/* <svg
          className='pricing-deco-img'
          enableBackground='new 0 0 300 100'
          height='100px'
          id='Layer_1'
          preserveAspectRatio='none'
          version='1.1'
          viewBox='0 0 300 100'
          width='300px'
          x='0px'
          xmlSpace='preserve'
          y='0px'
        >
          <path
            className='deco-layer deco-layer--1'
            d='M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z'
            fill='#FFFFFF'
            opacity='0.6'
          />
          <path
            className='deco-layer deco-layer--2'
            d='M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z'
            fill='#FFFFFF'
            opacity='0.6'
          />
          <path
            className='deco-layer deco-layer--3'
            d='M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716H42.401L43.415,98.342z'
            fill='#FFFFFF'
            opacity='0.7'
          />
          <path
            className='deco-layer deco-layer--4'
            d='M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z'
            fill='#FFFFFF'
          />
        </svg> */}
        </div>
        {data.name ||
          (data.address && (
            <div className='std_info'>
              {data.name && <h4 className='text-dark'>{data.name}</h4>}
              {data.address && (
                <h5 className='text-address text-secondary'>{data.address}</h5>
              )}
            </div>
          ))}
      </div>

    



    </div>
  );
};

export default StudentCard;
