import React from "react";
import "./style.css";
import Typo from "../Typo/Typo";
import Button_1 from "./../Buttons/Button_1/Button_1";
import {arrowLeft} from "../../assets/svgIcons";
import ShowMoreButton from "../Buttons/ShowMoreButton/ShowMoreButton";
import { useMediaQuery } from "../../customHooks/useMediaQueries";

const SectionTitle = ({title, showMore, showMoreText, onClick, ...props}) => {

  const smallScreens = useMediaQuery("(max-width:768px)");


  return (
    <div className='section_title' onClick={onClick}>
      <div className='d-none d-md-block'></div>
      <Typo
        style={{
          textWrap: "nowrap",
        }}
        {...props}
        positon='center'
        className={`sec__title ${smallScreens ? "fs-5" : "fs-4" }`}
      >
        {title}
      </Typo>

      <div className='show_more text-start fs-5'>
        {" "}
        {showMore && <ShowMoreButton showMoreText={showMoreText} />}
      </div>
    </div>
  );
};

export default SectionTitle;
