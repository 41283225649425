import React from "react";
import "./style.css";
import {
  phone_linear,
  Phone_outlined,
  PhoneIcon,
} from "../../../assets/svgIcons";
const TeacherInfo = ({data}) => {
  return (
    <div className='teacher_info_bio container'>
      {data.name && (
        <div className='teacher_info_bio_name mt-4'>
          {data?.name}
          {data.nick_name && (
            <span className='fs-6 fw-bolder text-secondary'>
              ({data.nick_name})
            </span>
          )}
        </div>
      )}

      {data.phone && (
        <div className='teacher_info_phone d-flex align-item-center gap-3 fw-bolder'>
          <span className='text-primary'>{PhoneIcon}</span> {data.phone}{" "}
        </div>
      )}
      {data.bio && <div className='teacher_info_bio_bio shadow-lg bg-light rounded rounded-4'>{data.bio}</div>}
    </div>
  );
};

export default TeacherInfo;
