

import React from 'react'
import "./style.css"
import { arrowLeft } from '../../../assets/svgIcons'
const ShowMoreButton = ({ onClick ,  showMoreText}) => {
  return (
       <button className='show_more_btn' onClick={onClick ? onClick : null }>
          <span>{showMoreText || "عرض المزيد"}</span>
          {arrowLeft}
        </button>
  )
}

export default ShowMoreButton
