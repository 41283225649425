import React, {useEffect, useState} from "react";
import "./style.css";
import SectionTitle from "../../../utils/SectionTitle/SectionTitle";
import Splatter from "../../../assets/svgs/Splatter";
import EventCard from "./../../Cards/EventCard/EventCard";
import {useMediaQuery} from "../../../customHooks/useMediaQueries";
import axios from "axios";
import {BASE_URL} from "../../../API/BASE_URL";
import toast from "react-hot-toast";
import {useAsyncError, useNavigate} from "react-router-dom";
import Spinner from "../../../utils/Spinner/Spinner";
import EmptyContent from "../../../utils/EmptyContent/EmptyContent";
const CurrentEvents = () => {
  const smallScreens = useMediaQuery("(max-width:768px)");

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const getTeachersData = async () => {
    setLoading(true);
    await axios
      .get(`${BASE_URL}/info/select_current_events.php`)
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setData(res?.data?.message);
        } else {
          toast.error("There is a problem in events!");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getTeachersData();
  }, []);

  const navigate = useNavigate();

  return data && data.length >= 1 && Array.isArray(data) ? (
    <div className='current_events container'>
      <SectionTitle
        color={"#4E2F7D"}
        my={"30px"}
        fw={"bolder"}
        variant={smallScreens ? "h4" : "h1"}
        title={"الأحداث الجارية"}
        showMore={true}
        onClick={() => navigate("all-events")}
        showMoreText={"عرض كل الأحداث"}
      />
      {loading ? (
        <Spinner variant={"io"} />
      ) : (
        <div className='curren_event_content mt-3'>
          {/* <div className="splatter">
        <Splatter className="splattert_svg"/>
        </div> */}
          {
            data && data.length >= 1 && Array.isArray(data)
              ? data.reverse().map((item, index) => {
                  return <EventCard data={item} key={index} />;
                })
              : ""
            // <EmptyContent size={"large"} text={"لا يوجد أحداث الى الآن!"} />
          }
        </div>
      )}
    </div>
  ) : (
    ""
  );
};

export default CurrentEvents;
