import React, { useState } from "react";
import "./style.css";
import bgImage from "../../../../assets/images/nature_banner_2.jpg";
import GlassyBackground from "./../../../../utils/GlassyBackground/GlassyBackground";
import {edit_2, exitModal, eyeOn} from "../../../../assets/svgIcons";
import Tooltip from './../../../../utils/Tooltip/Tooltip';
import Modal from "../../../../utils/Modal/Modal";

const TeacherBackground = ({banner}) => {


    const [modal , setModal] = useState(false);
    const handleImageError = (event) => {
      event.target.src = bgImage;   
    }
  


  return (
    <div className='teacher_bg_banner'>
      <img onError={handleImageError} src={banner || bgImage} alt='' />
      <div className='teacher_bg_banner_overlay d-flex align-items-start'>
    <div className="d-inline" onClick={()=>setModal(true)}>

        <Tooltip  position="right" className={"d-flex"} title={"عرض صورة الغلاف"}>
        <GlassyBackground className={"p-2 pointer hover_scale"} blur={5}>

        <div className="text-white" >{eyeOn}</div>
        </GlassyBackground>
        </Tooltip>
    </div>
      </div>

      <Modal
        animation={true}
        overlay={true}
        style={{backgroundColor:"transparent" , boxShadow:"none" }}
        size={"100%"}
        avtiveOverlay
        show={modal}
        onClose={() => setModal(false)}
        children={
          <>
            <img onError={handleImageError} className="banner_modal" src={ banner || bgImage } alt="" />
            <div className="closebtn pointer" onClick={()=> setModal(false)}>{exitModal}</div>
          </>
        }
      />




    </div>
  );
};

export default TeacherBackground;
