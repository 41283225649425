import React from "react";
import "./style.css";
// import logo from "./logo.png";
import {chevron_down, userInCircle} from "./svgs";
import {Link, NavLink, useNavigate} from "react-router-dom";
import Logo from "../../assets/svgs/logo";
import Button_1 from "../../utils/Buttons/Button_1/Button_1";
import Button_2 from "../../utils/Buttons/Button_2/Button_2";
import IconButton from "../../utils/Buttons/IconButton/IconButton";
import {userIconFill} from "../../assets/svgIcons";
import MenuSwitch from "../../utils/menuSwitch/MenuSwitch";



import sahla from "../../assets/images/Sahla_logo.png"
const Header = ({openSide, setOpenSide}) => {
 
  const navigate = useNavigate();

  return (
    <div className='header_container'>
      <div className='header_content'>
        <div className='side_icon'>
          <MenuSwitch
            checked={openSide}
            id={"drawer"}
            onChange={(e) => setOpenSide(!openSide)}
          />
        </div>
        <div className='left_side'>
          <div className='logo pointer' onClick={()=> navigate("/")}>
              <img  src={sahla} alt="" />
          </div>
          <div className='left_side_items'>
            <NavLink to={"/aboutus"}>معلومات عنا</NavLink>
            <Link to={"/subjects"}>المواد الدراسية</Link>
            <NavLink to={"/all-teachers"}>المعلمون</NavLink>
            <NavLink to={"/all-events"}>الأحداث الجارية</NavLink>
         
            
          </div>
        </div>
        {/* <div className='right_side gap-3'>
          <IconButton icon={userIconFill} />
          <Button_2 title={"كُن مُعلماً"} />
        </div> */}
      </div>
    </div>
  );
};

export default Header;
