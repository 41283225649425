export const arrowRight = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='icon icon-tabler icon-tabler-chevrons-right'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    stroke-width='2'
    stroke='currentColor'
    fill='none'
    stroke-linecap='round'
    stroke-linejoin='round'
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none'></path>
    <path d='M7 7l5 5l-5 5'></path>
    <path d='M13 7l5 5l-5 5'></path>
  </svg>
);

export const arrowLeft = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth={2}
    strokeLinecap='round'
    strokeLinejoin='round'
    className='icon icon-tabler icons-tabler-outline icon-tabler-chevrons-left'
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M11 7l-5 5l5 5' />
    <path d='M17 7l-5 5l5 5' />
  </svg>
);
