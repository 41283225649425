import React, { useState } from "react";
import TeacherBackground from "./TeacherBackground/TeacherBackground";
import TeacherImage from "./TeacherImage/TeacherImage";
import "./style.css";
import Modal from "../../../utils/Modal/Modal";
import { exitModal } from "../../../assets/svgIcons";
import img from "../../../assets/images/std_1.jpeg"


const TeacherBanner = ({banner , image}) => {
  const [modal, setModal] = useState(false);


  
  return (
    <div >
      <div className='teacherBackground'>
        <TeacherBackground banner={banner}/>
        <div className='teacherBackground_teacher_image'>
          <TeacherImage modal={modal} setModal={setModal} image={image} />
        </div>
      </div>
      <Modal
        animation={true}
        overlay={true}
        style={{backgroundColor:"transparent" , boxShadow:"none" }}
        size={"100%"}

        show={modal}
        onClose={() => setModal(false)}
        children={
          <div className="flex">
            <img className="teacher_modal_image" src={ image} alt="" />
            <div className="closebtn pointer" onClick={()=> setModal(false)}>{exitModal}</div>
          </div>
        }
      />
    </div>
  );
};

export default TeacherBanner;
