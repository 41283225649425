import React, {useState, useEffect} from "react";

import TeacherCard from "../../components/Cards/TeacherCard/TeacherCard";
import Tilt from "react-parallax-tilt";
import Typo from "./../../utils/Typo/Typo";
import Tooltip from "./../../utils/Tooltip/Tooltip";
import {Player} from "@lottiefiles/react-lottie-player";
import lottie from "../../assets/lotties/Animation - 1725796118810.json";
import axios from "axios";
import {BASE_URL} from "../../API/BASE_URL";
import toast from "react-hot-toast";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import SectionTitle from "../../utils/SectionTitle/SectionTitle";
import {useMediaQuery} from "../../customHooks/useMediaQueries";
import SearchInput from "./../../utils/SearchInput/SearchInput";
import EmptyContent from "../../utils/EmptyContent/EmptyContent";
import Spinner from "../../utils/Spinner/Spinner";
import { Helmet } from "react-helmet";
import UseGETHomeData from "../../customHooks/UseGETHomeData";

const SubjectTeachers = () => {
  const [loading, setLoading] = useState(false);
  const [teachersData, setTeachersData] = useState([]);
  const [originalData, setOriginalData] = useState([]);


  const {sub_id} = useParams()

  const [subjectsData , setSubjectData] = useState({})

  const [subject_exist , setSubject_exist] = useState()

  const getTeachersData = async () => {
    setLoading(true);

    await axios
      .get(`${BASE_URL}/info/select_teacher.php`)
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setTeachersData(res?.data?.message.filter(item => item.subject_id == sub_id));
          setOriginalData(res?.data?.message.filter(item => item.subject_id == sub_id));
        } else {
          toast.error("There is a problem in subjects teachers!");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getTeachersData();
    getSubjectsData();

  }, []);

  const getSubjectsData = async () => {

    await axios
      .get(`${BASE_URL}/info/select_subject.php`)
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setSubjectData(res?.data?.message.find(item => item.subject_id == sub_id));
        } else {
          toast.error("There is a problem in subjects");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
      });
  };
  const [searchValue, setSearchValue] = useState("");







  useEffect(() => {
    if (
      originalData &&
      originalData.length >= 1 &&
      Array.isArray(originalData)
    ) {
      if (searchValue.length >= 1) {
        const newData = originalData.filter((item) => {
          if (searchValue && !item.name.includes(searchValue)) {
            return false;
          }

          return true;
        });
        setTeachersData(newData);
      } else {
        setTeachersData(originalData);
      }
    }
  }, [searchValue, originalData]);

  const navigate = useNavigate();

  if(!subjectsData){
    return <Navigate to={"/subjects"} replace />
  }

  const {homeData, getHomeData, setHomeData, loading:homeLoading}  = UseGETHomeData()


  

  return (
    <>
    <Helmet>
          <title>سهلة |  {subjectsData?.name ? subjectsData?.name : ""}</title>
          <meta name='description' content={homeData?.info?.body} />
        </Helmet>

    <div className='container py-5'>
      <div className='teacher_section container' style={{minHeight:"90vh"}}>
        <div className='lottie_animation'>
          <Player
            autoplay
            loop
            src={lottie} // You can also use a URL here, like 'https://assets.lottiefiles.com/packages/lf20_yourfile.json'
            style={{transform: "scale(1.4)"}}
          />
          <Player
            autoplay
            loop
            src={lottie} // You can also use a URL here, like 'https://assets.lottiefiles.com/packages/lf20_yourfile.json'
            style={{transform: "scale(1.4)"}}
          />
        </div>

        <div className='row align-items-center mb-5 '>
          <div className='col-md-6 col-12 mb-sm-4'>
            <Typo
              positon={"center"}
              fw={"bolder"}
              color={"#4E2F7D"}
              variant={"h2"}
            >
              {subjectsData?.name}
            </Typo>
          </div>
          <div className='col-md-6  col-12 '>
            {!loading && (
              <SearchInput onChange={(e) => setSearchValue(e.target.value)} />
            )}
          </div>
        </div>

        {loading ? (
          <div
            className='p-5'
            style={{
              minHeight: "90vh",
            }}
          >
            <Spinner variant={"io"} />
          </div>
        ) : originalData &&
          originalData.length >= 1 &&
          Array.isArray(originalData) ? 
            teachersData.length >= 1 ?


          
          
          (

            

          <div
            style={{
              minHeight: "90vh",
            }}
            className='teacher_section_content'
          >
            {
            
            teachersData?.map((item, index) => {
              return (
                <Tilt className='w-100' key={index} data={item}>
                  <Tooltip
                    hide={!item?.nick_name}
                    className={"w-100"}
                    bg='#2f1d33'
                    position='top'
                    title={
                      <Typo variant={"h5"} color={"#FFF"}>
                        {item?.nick_name}#
                      </Typo>
                    }
                  >
                    <TeacherCard
                      onClick={(e) => {
                        e.stopPropagation();

                        navigate(`/teacher/${item.teacher_id}`, {
                          state: {data: item},
                        });
                      }}
                      data={item}
                    />
                  </Tooltip>
                </Tilt>
              );
            })
            
            
            }
          </div>
        )
        :
        <Typo
        variant={"h2"}
        color={"var(--vio-1)"}
        fw={"bolder"}
        positon={"center"}
        className=''
      >
        لا يوجد نتائج....
      </Typo>
        
        
        
        : (
          <EmptyContent size={"large"} text={"لا يوجد مدرسين إلى الآن!"} />
        )}
      </div>
    </div>
    </>

  );
};

export default SubjectTeachers;
