import React, {useEffect, useState} from "react";
import TimeLineYears from "./subComponents/TimeLineYears/TimeLineYears";
import TimeLineContent from "./subComponents/TimeLineContent/TimeLineContent";
import SectionTitle from "../../../utils/SectionTitle/SectionTitle";
import Button_2 from "./../../../utils/Buttons/Button_2/Button_2";
import EmptyContent from "../../../utils/EmptyContent/EmptyContent";
import "./style.css";
import { timeline } from "../../../assets/svgIcons";
const TimeLineSection = ({data}) => {
  const [selectedYear, setSelectedYear] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState({});

  useEffect(() => {
    if (data && data.generations) {
      setSelectedTeam(data?.generations[0]);
      setSelectedYear(data?.generations[0]?.timeline[0]);
    }
  }, [data]);

  return data.generations &&
    data.generations.length >= 1 &&
    Array.isArray(data.generations) ? (
    <div className='container mt-5'>
      <SectionTitle
        color={"#4E2F7D"}
        my={"30px"}
        fw={"bolder"}
        variant={"h3"}
       title={<span> {timeline} الخط الزمني </span>}

      />

      <div className='row'>
        <div className='col-sm-2 col-12'>
          <TimeLineYears
            data={selectedTeam}
            selectedYear={selectedYear}
            setSelectedYear={setSelectedYear}
          />
        </div>
        <div className='col-sm-10 col-12 overflow-hidden'>
          <div className='d-flex generations_btns  justify-content-center gap-1 my-5'>
            {data.generations?.map((item, index) => {
              return (
                item.timeline &&
                item.timeline.length >= 1 && (
                  <Button_2
                    onClick={() => {
                      setSelectedTeam(item);
                      setSelectedYear(item.timeline[0]);
                    }}
                    key={index}
                    border={selectedTeam.name != item.name}
                    title={item.name}
                  />
                )
              );
            })}
          </div>

          {selectedTeam?.timeline && selectedTeam.timeline.length >= 1 ? (
            <TimeLineContent data={selectedYear} />
          ) : (
            <EmptyContent
              size={"large"}
              text={"  لا يوجد  خطة زمنية لهذا الصف"}
              typoProps={{variant: "h6", py: "20px"}}
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};

export default TimeLineSection;
