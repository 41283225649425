
import React from 'react'
import "./style.css"
const MenuSwitch = ({id , checked , onChange}) => {
  return (
<div className="menu_switch">
    <input id={id} checked = {checked} onChange={onChange} type="checkbox"/>
    <div>
        <span className="line-1"></span>
        <span className="line-2"></span>
        <span className="line-3"></span>
    </div>
</div>
  )
}

export default MenuSwitch
