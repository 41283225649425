import React from "react";

import Logo from "../../assets/svgs/logo";

import "./style.css";
import StyledBackground from "../../utils/StyledBackground/StyledBackground";
import IconButton from "./../../utils/Buttons/IconButton/IconButton";
import {
  social_facebook,
  social_instagram,
  social_x,
  social_youtube,
} from "../../assets/svgIcons";
import { useNavigate } from "react-router-dom";
import Tooltip from './../../utils/Tooltip/Tooltip';
import sahlaWhite from "../../assets/images/Sahla_logo_white.png"

const Footer_1 = ({bgColor}) => {
  const navigate = useNavigate()
  return (
    <StyledBackground className={"shadow-lg"}>
      <div className='footer1_container'>
        {/* <div style={{backgroundColor:bgColor}} className="footer1_topWave">{footerWave}</div> */}
        <div className='footer1_content'>
          <div className='footer1_header'>
            <div className='footer1_logo pointer' onClick={()=> navigate("/")}>
              {/* <img src={logo} alt='' /> */}
              <img src={sahlaWhite} alt="" />
            </div>
            <div className='socials'>

              <IconButton onClick={() => window.open("https://www.facebook.com/bio.lovers.20" , "_blank")} icon={social_facebook} bgColor={"#1877F2"} />
              {/* <IconButton
                icon={social_instagram}
                bgColor={"rgb(191, 35, 126)"}
              />
              <IconButton icon={social_x} bgColor={"black"} />
              <IconButton icon={social_youtube} bgColor={"rgb(189, 21, 21)"} /> */}
            </div>
          </div>

          <div className='footer1_columns'>
            <div className='column'>
              <div className='col_item' onClick={()=> navigate("/aboutus")}>معلومات عنا</div>
              <div className='col_item' onClick={()=> navigate("/subjects")}>المواد الدراسية</div>
              <div className='col_item' onClick={()=> navigate("/all-teachers")}>المعلمون</div>
              <div className='col_item' onClick={()=> navigate("/all-events")}>الأحداث الجارية</div>
            </div>

            {/* <div className='column'>
              <div className='col_item'>سياسة الخصوصية</div>
              <div className='col_item'>أسئلة متكررة</div>
            </div> */}
          </div>

          <div className='footer1_copyRight text-center'>
            Copyright © 2024 All rights reserved | Camp Coding
          </div>
        </div>
      </div>
    </StyledBackground>
  );
};

export default Footer_1;
