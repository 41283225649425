import AboutUs from "../pages/aboutUs/AboutUs";
import AllEvents from "../pages/AllEvents/AllEvents";
import AllTeachers from "../pages/AllTeachers/AllTeachers";
import HomePage from "../pages/HomePage/HomePage";
import Subjects from "../pages/Subjects/Subjects";
import SubjectTeachers from "../pages/SubjectTeachers/SubjectTeachers";
import TeacherPage from "../pages/TeacherPage/TeacherPage";

export const routes = [
  {
    path:"/",
    component:<HomePage />
  },
  {
    path:"/teacher/:id",
    component:<TeacherPage />
  },
  {
    path:"/all-teachers",
    component:<AllTeachers />
  },
  {
    path:"/all-events",
    component:<AllEvents />
  },
  {
    path:"/aboutus",
    component:<AboutUs />
  },
  {
    path:"/subjects",
    component:<Subjects />
  },
  {
    path:"/subjects/:sub_id",
    component:<SubjectTeachers />
  },
]