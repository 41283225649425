import React, {useEffect, useState} from "react";
import Modal from "./../../../../utils/Modal/Modal";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvent,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import axios from "axios";

// Set up a custom icon for the marker to avoid missing marker issues
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
const LocationLogger = ({selectedPositions, setSelectedPositions}) => {
  useMapEvent("click", (event) => {
    const {lat, lng} = event.latlng; // Get latitude and longitude of click
    setSelectedPositions({
      lat,
      lng,
    });

    console.log(`Map clicked at latitude: ${lat}, longitude: ${lng}`);
  });

  return null; // This component doesn't render anything, just listens to events
};

const BranchLocationModal = ({open, setopen, data}) => {
  const [selectedPositions, setSelectedPositions] = useState({
    lat: "30.811848178053072",
    lng: "31.16437403928783",
  });

  const [addressData, setAddressData] = useState([]);

  const getLocationName = async () => {
    try {
      const responseAr = await axios.get(
        ` https://nominatim.openstreetmap.org/reverse?format=json&lat=${
          selectedPositions.lng || data?.longtiude
        }&lon=${
          selectedPositions.lat || data?.lattiude
        }&zoom=18&addressdetails=1`,
        {headers: {"Accept-Language": "ar"}}
      );
      const addressAr = responseAr.data.address;
      const nameAr = [
        addressAr.road,
        addressAr.neighbourhood,
        addressAr.suburb,
        addressAr.city ||
          addressAr.town ||
          addressAr.village ||
          addressAr.hamlet,
        addressAr.state,
        addressAr.country,
      ]
        .filter(Boolean)
        .join(", ");
      setAddressData(nameAr);
      console.log(addressAr);
    } catch (error) {
      console.error("Error fetching location name:", error);
      setAddressData("موقع غير معروف");
    }
  };

  useEffect(() => {
    getLocationName(selectedPositions.lat, selectedPositions.lng);
  }, [selectedPositions.lat, selectedPositions.lng, data]);

  return (
    data && (
      <Modal
        title={
          <div className='text-center mt-3 fs-3 w-100'>{data?.place} </div>
        }
        animation={true}
        showCloseBtn={true}
        overlay={true}
        size={"80%"}
        show={open}
        onClose={() => setopen(false)}
        children={
          <>
            <MapContainer
              center={[data?.lattiude, data?.longtiude]}
              zoom={13}
              style={{height: "400px", width: "100%"}}
            >
              <TileLayer
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <LocationLogger
                selectedPositions={selectedPositions}
                setSelectedPositions={setSelectedPositions}
              />
              <Marker position={[data?.lattiude, data?.longtiude]}>
                <Popup>
                  {/* {addressData.} */}
                  {data.title} - {data.description}
                </Popup>
              </Marker>
            </MapContainer>

            {/* {
            open &&
            <iframe
              src={data?.map}
              width={"100%"}
              height={450}
              style={{border: 0}}
              allowFullScreen=''
              loading='lazy'
              referrerPolicy='no-referrer-when-downgrade'
            />
          } */}
          </>
        }
      />
    )
  );
};

export default BranchLocationModal;
