import React, {useEffect, useState} from "react";
import "./style.css";

import axios from "axios";
import toast from "react-hot-toast";
import CurrentEvents from "../../components/HomeComponents/CurrentEvents/CurrentEvents";
import {useMediaQuery} from "../../customHooks/useMediaQueries";
import {BASE_URL} from "../../API/BASE_URL";
import Typo from "../../utils/Typo/Typo";
import SearchInput from "../../utils/SearchInput/SearchInput";
import EventCard from "../../components/Cards/EventCard/EventCard";
import Spinner from "../../utils/Spinner/Spinner";
import EmptyContent from "../../utils/EmptyContent/EmptyContent";
import UseGETHomeData from "../../customHooks/UseGETHomeData";
import {Helmet} from "react-helmet";

const AllEvents = () => {
  const smallScreens = useMediaQuery("(max-width:768px)");

  const [loading, setLoading] = useState(false);
  const [teachersData, setTeachersData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [data, setData] = useState();

  const [searchValue, setSearchValue] = useState("");

  const getTeachersData = async () => {
    setLoading(true);
    await axios
      .get(`${BASE_URL}/info/select_current_events.php`)
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setData(res?.data?.message);
          setOriginalData(res?.data?.message);
        } else {
          toast.error("There is a problem in teachers section!");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getTeachersData();
  }, []);

  useEffect(() => {
    if (
      originalData &&
      originalData.length >= 1 &&
      Array.isArray(originalData)
    ) {
      if (searchValue.length >= 1 ) {
        const newData = originalData.filter((item) => {
          if (
            searchValue &&
            !item.title.includes(searchValue) &&
            !item.description.includes(searchValue) &&
            !item.address.includes(searchValue)
          ) {
            return false;
          }

          return true;
        });
        setData(newData);
      } else {
        setData(originalData);
      }
    }
  }, [searchValue, originalData]);

  const {
    homeData,
    getHomeData,
    setHomeData,
    loading: homeLoading,
  } = UseGETHomeData();

  return (
    <>
      <Helmet>
        <title>سهلة | الأحداث الجارية </title>
        <meta name='description' content={homeData?.info?.body} />
      </Helmet>
      <div className='container'>
        <div className='current_events container' style={{minHeight: "90vh"}}>
          <div className='row align-items-center mb-5 '>
            <div className='col-md-6 col-12 mb-sm-4'>
              <Typo
                positon={"center"}
                fw={"bolder"}
                color={"#4E2F7D"}
                variant={"h2"}
              >
                كل الأحداث
              </Typo>
            </div>
            <div className='col-md-6  col-12 '>
              {!loading && (
                <SearchInput
                  placeholder={"البحث عن حدث"}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              )}
            </div>
          </div>

          {loading ? (
            <div
              className='p-5'
              style={{
                minHeight: "90vh",
              }}
            >
              <Spinner variant={"io"} />
            </div>
          ) : (
            <div className='curren_event_content'>
              {/* <div className="splatter">
        <Splatter className="splattert_svg"/>
        </div> */}
              {originalData &&
              originalData.length >= 1 &&
              Array.isArray(originalData) ? (
                data && data.length >= 1 ? (
                  data.reverse().map((item, index) => {
                    return <EventCard data={item} key={index} />;
                  })
                ) : (
                  <Typo
                    variant={"h2"}
                    color={"var(--vio-1)"}
                    fw={"bolder"}
                    positon={"center"}
                    className=''
                  >
                    لا يوجد نتائج....
                  </Typo>
                )
              ) : (
                <EmptyContent size={"large"} text={"لا يوجد أحداث إلى الآن!"} />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AllEvents;
