import React, {useEffect} from "react";
import "./style.css";

const Tooltip = ({children, hide, className, title, noArrow, style, ...props}) => {
  const mainClassName = "tooltiptext";
  const compineClassNames = mainClassName + " " + className;

  const {bg, color, position} = props;
  const colors = {
    primary: "#0d6efd", // Blue
    secondary: "#6c757d", // Gray
    success: "#198754", // Green
    danger: "#dc3545", // Red
    warning: "#ffc107", // Yellow
    info: "#0dcaf0", // Cyan
    light: "#f8f9fa", // Light Gray
    dark: "#212529", // Dark Gray
    blue: "#0d6efd",
    indigo: "#6610f2",
    purple: "#6f42c1",
    pink: "#d63384",
    red: "#dc3545",
    orange: "#fd7e14",
    yellow: "#ffc107",
    green: "#198754",
    teal: "#20c997",
    cyan: "#0dcaf0",
    white: "#ffffff",
    black: "#000000",
  };
  const bgColor = bg ? (colors[bg] ? colors[bg] : bg) : "#222";

  const positons = {
    top: "top",
    right: "right",
    bottom: "bottom",
    left: "left",
  };

  useEffect(() => {
    console.log(props);
  }, []);

  return (
    <div className={`custom_tooltip ${hide ? "hide" : ""}`} style={style}>
      {children}
      {
        !hide &&
      <div
        className={`tooltiptext ${positons[position]}`}
        style={{
          backgroundColor: bg ? (colors[bg] ? colors[bg] : bg) : "#222",
          color: color ? (colors[color] ? colors[color] : color) : "#FFF",
        }}
      >
        {title}
        {!noArrow && (
          <div
            style={{
              borderColor:
                position == "right"
                  ? `  transparent ${bgColor} transparent transparent`
                  : position == "left"
                  ? `  transparent transparent  transparent ${bgColor}`
                  : position == "bottom"
                  ? ` transparent transparent${bgColor} transparent`
                  : `${bgColor} transparent transparent transparent`,
            }}
            className={`tooltip_arrow ${positons[position]}`}
          ></div>
        )}
      </div>
      }
    </div>
  );
};

export default Tooltip;
