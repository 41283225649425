import React from "react";
import "./style.css";
import  Tilt  from 'react-parallax-tilt';
const SubjectCard = ({item ,  onClick}) => {
  return (
    <Tilt 
    glareEnable={true}
    glareMaxOpacity={0.8}
    glareColor="white"
    glarePosition="bottom"
    glareBorderRadius="20px"
    
    
    >

    <div className='subjectCard' onClick={onClick}>
      <div class='content'>
        <div className='subject_image'>
          <img
            src={
              item?.icon
            }
            alt=''
            />
        </div>
        
        <h2   className=" m-0 subjectCard_title fw-bolder ">{item.name}</h2>
        
      </div>
    </div>
            </Tilt>
  );
};

export default SubjectCard;
