import React, {useState, useRef, useEffect, Children} from "react";
import "./style.css";
import {Swiper, SwiperSlide} from "swiper/react";

// Import Swiper styles
import "swiper/css/navigation";
import "swiper/css";

// import "../../HomePage/NotiRump/swiperStyle.css";
// import "./swiper.css"
import "swiper/css/pagination";

// import required modules
import {EffectCoverflow, Navigation, Pagination} from "swiper/modules";

import "swiper/css/effect-coverflow";

import image from "../../assets/images/teacher__1.png";

import cx from "classnames"
import {arrowLeft, arrowRight} from "./svgIcons";
import Modal from "../Modal/Modal";
import { exitModal, star_medal } from "../../assets/svgIcons";
import Typo from "../Typo/Typo";

//----------------------

const PetFood = ({data , children}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef(null);
  const [modal , setModal] = useState(false)
  const [selectedData , setSelectedData] = useState(null)

  const handleSlideChange = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      setActiveIndex(swiperRef.current.swiper.activeIndex);
      console.log(swiperRef.current.swiper.activeIndex);
    }
  };

  return (
    <div className='pet_food_container'>
      {/* {animated_arrow} */}

      <div className='pet_food_swiper'>
        <>
          <Swiper
            ref={swiperRef}
            onSlideChange={handleSlideChange}
            // loopFillGroupWithBlank={true}
            // ------------------------
            // effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={"3"}
            // pagination={true}
            // spaceBetween={40}
            // loop={true}
            navigation={{
              nextEl: ".custom-prev",
              prevEl: ".custom-next",
            }}
            initialSlide={2}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 1,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 1,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Pagination, Navigation]}
            // modules={[EffectCoverflow, Pagination, Navigation]}
            // coverflowEffect={{
            //   rotate: 10,
            //   stretch: 50,
            //   depth: 200,
            //   modifier: 1,
            // }}
            className='mySwiper'
          >
            {
            data?.map((item, index) => {
              return (
                <SwiperSlide key={index} className={cx('swiper-slide' , {"active": index == activeIndex})}>
                <div
                  onClick={()=> {
                    setModal(true);
                    setSelectedData(item)
                  }}
                    className={`food_card ${
                      index < activeIndex
                      ? "moreThanActive"
                      : index > activeIndex
                      ? "lesThanActive"
                        : ""
                    }`}
                  >
                  {React.cloneElement(children, { data: item })}

                  </div>
                </SwiperSlide>
              );
            })}

            <div className='custom-navigation pet_food'>
              <div className='custom-prev'>{arrowLeft}</div>
              <div className='custom-next'>{arrowRight}</div>
            </div>

            {/* <div className="custom-prev">{arrowLeft}</div>
          <div className="custom-next">{arrowRight}</div> */}
          </Swiper>
        </>
      </div>


      <Modal
        animation={true}
        overlay={true}
        style={{backgroundColor:"transparent" , boxShadow:"none" }}
        size={"100%"}
        showCloseBtn={true}
        show={modal}
        onClose={() => setModal(false)}
        children={
          
          
          <div className="d-flex align-items-center justify-content-center">

            {
              modal &&
                        <div className="closebtn pointer" onClick={()=> setModal(false)}>{exitModal}</div>
            }

          <div  className='best_std_card w-100 h-100 shado' onClick={()=> setModal(true)}>
      <div className='std_card_content w-100 h-100'>
      <Typo positon={"center"} my={"30px"} fw={"bolder"} variant={"h5"} color={"var(--vio-1)"}>أوائل الطلاب</Typo>

        <div className='std_image'>
          <img width={500} src={selectedData?.image} alt='' />
          <div className='medal_icon'>{star_medal}</div>
          {/* <svg
          className='pricing-deco-img'
          enableBackground='new 0 0 300 100'
          height='100px'
          id='Layer_1'
          preserveAspectRatio='none'
          version='1.1'
          viewBox='0 0 300 100'
          width='300px'
          x='0px'
          xmlSpace='preserve'
          y='0px'
        >
          <path
            className='deco-layer deco-layer--1'
            d='M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z'
            fill='#FFFFFF'
            opacity='0.6'
          />
          <path
            className='deco-layer deco-layer--2'
            d='M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z'
            fill='#FFFFFF'
            opacity='0.6'
          />
          <path
            className='deco-layer deco-layer--3'
            d='M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716H42.401L43.415,98.342z'
            fill='#FFFFFF'
            opacity='0.7'
          />
          <path
            className='deco-layer deco-layer--4'
            d='M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z'
            fill='#FFFFFF'
          />
        </svg> */}
        </div>
        {selectedData?.name ||
          (selectedData?.address && (
            <div className='std_info'>
              {selectedData?.name && <h4 className='text-dark'>{selectedData?.name}</h4>}
              {selectedData?.address && (
                <h5 className='text-address text-secondary'>{selectedData?.address}</h5>
              )}
            </div>
          ))}
      </div>
      </div>
          
          </div>





        }
      />

    </div>
  );
};

export default PetFood;
