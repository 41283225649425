import React, {useEffect, useState} from "react";
import PetFood from "../../../utils/PetFood/PetFood";
import SectionTitle from "./../../../utils/SectionTitle/SectionTitle";
import StudentCard from "./../../Cards/StudentCard/StudentCard";
import TimeLineYears from "../TimeLineSection/subComponents/TimeLineYears/TimeLineYears";
import TopStudentsYears from "./TopStudentsYears/TopStudentsYears";
import { star_medal } from "../../../assets/svgIcons";

function BestStudetnsSection({data}) {
  const [selectedYear, setSelectedYear] = useState(null);


  useEffect(()=>{
    if(data && data.student_years)
    setSelectedYear(data?.student_years[0]);
  },[data])

  

  return data.top_students &&
    data.top_students.length >= 1 &&
    Array.isArray(data.top_students) ? (
    <div className='best_students_section px-2 my-4 container'>
      <SectionTitle
        color={"#4E2F7D"}
        my={"30px"}
        fw={"bolder"}
        variant={"h3"}
        title={<span> {star_medal} أوائل الطلاب </span>}
        showMore={false}
      />
      <div className='row m-0 p-0'>
        <div className='col-sm-2 col-12 overflow-hidden '>
          <TopStudentsYears
            data={data}
            selectedYear={selectedYear}
            setSelectedYear={setSelectedYear}
          />
        </div>
        <div className='col-sm-10 -12 mt-4 mt-sm-0'>
          <PetFood data={selectedYear?.students} children={<StudentCard />} />
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}

export default BestStudetnsSection;
