import React from "react";
import "./style.css";
import cx from "classnames";
const TopStudentsYears = ({data , years, selectedYear, setSelectedYear}) => {

  console.log(data)


  return (
    <div className='time_line_container'>
      {data?.student_years?.map(
        (item, index) => {
          return (
            <div
              key={index}
              onClick={() => setSelectedYear(item)}
              className={cx("year", {active: selectedYear?.year_id == item?.year_id})}
            >
              {item.year}
            </div>
          );
        }
      )}
    </div>
  );
};

export default TopStudentsYears;
