import React, { useEffect, useState } from "react";
import "./style.css";
import StyledBackground from "./../../../utils/StyledBackground/StyledBackground";
import Tilt from "react-parallax-tilt";

import svg from "../../../assets/svgs/Learning-bro.svg";
import Typo from "./../../../utils/Typo/Typo";
import GlassyBackground from "./../../../utils/GlassyBackground/GlassyBackground";
import IconButton from "./../../../utils/Buttons/IconButton/IconButton";
import { useMediaQuery } from "../../../customHooks/useMediaQueries";
import axios from "axios";
import { BASE_URL } from "../../../API/BASE_URL";
import toast from "react-hot-toast";
import Spinner from './../../../utils/Spinner/Spinner';
const HomeBanner = ({bannerInfo}) => {

  const smallScreen = useMediaQuery("(max-width: 768px)");


  const [loading, setLoading] = useState(false);
  const [bannerData , setBannerData] = useState([])
  const getBannerData = async () => {
    setLoading(true);


    await axios
      .get(`${BASE_URL}/info/select_site_info.php`)
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setBannerData(res?.data?.message.info);
        } else {
          toast.error("There is a problem in subjects");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getBannerData();
  }, []);



  return (
    <StyledBackground wave={true}>
      <div className='home_banner '>
        <div className='row container'>
          <div className='col-12 col-md-8 banner_content'>
            {
              loading ? <div className="my-5"><Spinner variant={"io"} /> </div>: 

              <>
                <Typo positon={smallScreen ? "center" :"start"} fw={"bolder"} variant={"h2"} color={"light"}>
              {bannerData.title}
            </Typo>
            <Typo  positon={smallScreen ? "center" :"start"} width={ smallScreen ? "100%":"80%"} variant={"h5"} mt={"20px"} color={"light"}>
             {bannerData.body}
            </Typo>
           
            <Typo
              mt={smallScreen ? "30px":"70px"}
              fw={"bolder"}
              variant={"h5"}
              color={"rgb(226, 216, 84)"}
            >
              {" "}
              {bannerData.description}
            </Typo></>
            }
          </div>

          <div  className='col-12 col-md-4 text-start '>
            <Tilt gyroscope={true}>
              <img src={svg} alt='' />
            </Tilt>
          </div>
        </div>
      </div>
    </StyledBackground>
  );
};

export default HomeBanner;
