
import React from 'react'
import "./style.css"

import cx from "classnames"
const Button_2 = ({title , onClick ,className ,border , ...props}) => {
  return (
<button onClick={onClick} {...props} className={cx("button__2" ,{"border": border} , className)}>
  {title || "button"}
</button>
  )
}

export default Button_2
