import React, {useEffect, useState} from "react";
import Marquee from "react-fast-marquee";
// import Marquee from './../../../utils/Marquee/Marquee';
import "./style.css";
import {subjects} from "../../../data/subjectsData";
import axios from "axios";
import {BASE_URL} from "../../../API/BASE_URL";
import toast from "react-hot-toast";

import SubjectCard from "../../Cards/SubjectCard/SubjectCard";
import Spinner from "../../../utils/Spinner/Spinner";
import EmptyContent from "./../../../utils/EmptyContent/EmptyContent";
import {useNavigate} from "react-router-dom";
import SectionTitle from "../../../utils/SectionTitle/SectionTitle";
import {useMediaQuery} from "../../../customHooks/useMediaQueries";
const MarqueeSection = () => {
  const [loading, setLoading] = useState(false);
  const [bannerData, setBannerData] = useState([]);
  const smallScreens = useMediaQuery("(max-width:768px)");

  const [subjects, setSubjects] = useState([]);
  const getBannerData = async () => {
    setLoading(true);

    await axios
      .get(`${BASE_URL}/info/select_subject.php`)
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setSubjects(res?.data?.message);
        } else {
          toast.error("There is a problem in subjects");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getBannerData();
  }, []);

  const navigate = useNavigate();

  return (
    <div className='container'>
      <SectionTitle
        color={"#4E2F7D"}
        my={"30px"}
        fw={"bolder"}
        variant={smallScreens ? "h4" : "h1"}
        title={"المواد الدراسية"}
        showMore={true}
        onClick={() => navigate("/subjects")}
        showMoreText={"  عرض كل المواد"}
      />
      <div
        id='subjects'
        className='marquee_section'
        style={{
          direction: "ltr",
        }}
      >
        {
          loading ? (
            <Spinner variant={"io"} />
          ) : subjects && subjects.length >= 1 && Array.isArray(subjects) ? (
            <Marquee
              pauseOnHover
              className='gap-4 py-5'
              direction='right'
              style={{width: "100%"}}
              speed={40}
              gradient={false}
              loop={0}
            >
              <div className='flex gap-4'>
                {subjects.map((item, index) => {
                  return (
                    <SubjectCard
                      onClick={() => navigate(`/subjects/${item.subject_id}`)}
                      item={item}
                    />
                  );
                })}
              </div>
            </Marquee>
          ) : (
            ""
          )
          // <EmptyContent size={"large"}  text={"لا يوجد مواد دراسية إلى الآن!"}/>
        }
      </div>
    </div>
  );
};

export default MarqueeSection;
