export const subjects = [
  {
    id: 1,
    name: "الرياضيات",
    description: "دراسة الأرقام والعمليات الرياضية.",
  },
  {
    id: 2,
    name: "اللغة العربية",
    description: "تعلم اللغة العربية من حيث القراءة والكتابة والقواعد.",
  },
  {
    id: 3,
    name: "العلوم",
    description: "دراسة العلوم الطبيعية والبيئية.",
  },
  {
    id: 4,
    name: "التاريخ",
    description: "دراسة الأحداث التاريخية وتطور الحضارات.",
  },
  {
    id: 5,
    name: "الجغرافيا",
    description: "دراسة الأرض والمناخ والخرائط.",
  },
  {
    id: 6,
    name: "الفيزياء",
    description: "دراسة المادة والطاقة والتفاعلات بينهما.",
  },
  {
    id: 7,
    name: "الكيمياء",
    description: "دراسة المواد الكيميائية وخصائصها وتفاعلاتها.",
  },
  {
    id: 8,
    name: "الأحياء",
    description: "دراسة الكائنات الحية وتركيبها ووظائفها.",
  },
  {
    id: 9,
    name: "التربية الإسلامية",
    description: "تعلم القيم والمبادئ الإسلامية والفقه.",
  },
  {
    id: 10,
    name: "اللغة الإنجليزية",
    description: "تعلم اللغة الإنجليزية من حيث القراءة والكتابة والقواعد.",
  },
  {
    id: 11,
    name: "التربية الفنية",
    description: "تنمية المهارات الفنية والتعبير من خلال الفن.",
  },
  {
    id: 12,
    name: "التربية البدنية",
    description: "تعزيز اللياقة البدنية من خلال الأنشطة الرياضية.",
  },
  {
    id: 13,
    name: "العلوم الاجتماعية",
    description: "دراسة المجتمع والعلاقات الإنسانية.",
  },
  {
    id: 14,
    name: "الاقتصاد المنزلي",
    description: "تعلم المهارات المنزلية وإدارة الموارد.",
  },
  {
    id: 15,
    name: "الحاسب الآلي",
    description: "دراسة أساسيات الحوسبة والتكنولوجيا.",
  },
  {
    id: 16,
    name: "الفلسفة",
    description: "دراسة التفكير النقدي والأفكار الفلسفية.",
  },
  {
    id: 17,
    name: "علم النفس",
    description: "دراسة العقل والسلوك الإنساني.",
  },
  {
    id: 18,
    name: "علم الاجتماع",
    description: "دراسة المجتمع والبنية الاجتماعية.",
  },
  {
    id: 19,
    name: "التعليم المهني",
    description: "تعلم المهارات العملية والتقنية.",
  },
  {
    id: 20,
    name: "التربية الوطنية",
    description: "تعلم القيم الوطنية والحقوق والواجبات.",
  },
  {
    id: 21,
    name: "التربية الأسرية",
    description: "تعلم المهارات الحياتية والتربية الأسرية.",
  },
  {
    id: 22,
    name: "اللغة الفرنسية",
    description: "تعلم اللغة الفرنسية من حيث القراءة والكتابة والقواعد.",
  },
  {
    id: 23,
    name: "الموسيقى",
    description: "دراسة النغمات والإيقاعات والآلات الموسيقية.",
  },
  {
    id: 24,
    name: "علوم البيئة",
    description: "دراسة البيئة والمشاكل البيئية وطرق الحفاظ عليها.",
  },
  {
    id: 25,
    name: "التعليم التقني",
    description: "تعلم المهارات التقنية وتطبيقاتها العملية.",
  }
];
