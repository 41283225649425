import React, {useState, useEffect} from "react";
import moment from "moment";
import "./style.css"
const CountdownTimer = ({targetTime}) => {
  const calculateTimeLeft = () => {
    const now = moment();
    const target = moment(targetTime);
    const diff = target.diff(now); 

    if (diff <= 0) {
      return {days: 0, hours: 0, minutes: 0, seconds: 0};
    }

    const duration = moment.duration(diff);
    return {
      days: Math.floor(duration.asDays()), 
      hours: Math.floor(duration.hours()), 
      minutes: duration.minutes(), 
      seconds: duration.seconds(),
    };
  };

  const [remainingTime, setRemainingTime] = useState(calculateTimeLeft());
  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const timeLeft = calculateTimeLeft();
      setRemainingTime(timeLeft);

      if (
        timeLeft.days === 0 &&
        timeLeft.hours === 0 &&
        timeLeft.minutes === 0 &&
        timeLeft.seconds === 0
      ) {
        setIsFinished(true);
        clearInterval(interval);
      }
    }, 1000);

    // Clean up the interval when component unmounts
    return () => clearInterval(interval);
  }, [targetTime]);

  return (
      isFinished ? (
        <div>تم انتهاء الحدث</div>
      ) : (
        <div className='countDownTimer flex-wrap  d-flex justify-content-between gap-2 '>
          <span className="ps-2"> {remainingTime.days} يوم </span>
          <span className="">{remainingTime.hours} ساعة </span>
          <span className="">{remainingTime.minutes} دقيقة </span>
          <span className="">{remainingTime.seconds} ثانية</span>
        </div>
      )
  );
};

export default CountdownTimer;
