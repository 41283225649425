import React, {useState, useEffect} from "react";
import "./style.css";

import TeacherCard from "../../components/Cards/TeacherCard/TeacherCard";
import Tilt from "react-parallax-tilt";
import Typo from "./../../utils/Typo/Typo";
import Tooltip from "./../../utils/Tooltip/Tooltip";
import {Player} from "@lottiefiles/react-lottie-player";
import lottie from "../../assets/lotties/Animation - 1725796118810.json";
import axios from "axios";
import {BASE_URL} from "../../API/BASE_URL";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import {useMediaQuery} from "../../customHooks/useMediaQueries";
import SearchInput from "./../../utils/SearchInput/SearchInput";
import EmptyContent from "../../utils/EmptyContent/EmptyContent";
import Spinner from "../../utils/Spinner/Spinner";
import SubjectCard from "../../components/Cards/SubjectCard/SubjectCard";
import { Helmet } from "react-helmet";
import UseGETHomeData from "../../customHooks/UseGETHomeData";

const Subjects = () => {
  const navigate = useNavigate();

  const smallScreens = useMediaQuery("(max-width:768px)");
  const [loading, setLoading] = useState(false);
  const [bannerData, setBannerData] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const getSubjects = async () => {
    setLoading(true);

    await axios
      .get(`${BASE_URL}/info/select_subject.php`)
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setSubjects(res?.data?.message);
          setOriginalData(res?.data?.message);
        } else {
          toast.error("There is a problem in subjects");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSubjects();
  }, []);

  const {homeData, getHomeData, setHomeData, loading:homeLoading}  = UseGETHomeData()

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (
      originalData &&
      originalData.length >= 1 &&
      Array.isArray(originalData)
    ) {
      if (searchValue.length >= 1) {
        const newData = originalData.filter((item) => {
          if (searchValue && !item.name.includes(searchValue)) {
            return false;
          }

          return true;
        });
        setSubjects(newData);
      } else {
        setSubjects(originalData);
      }
    }
  }, [searchValue, originalData]);

  return (
    <>
     <Helmet>
        <title>سهلة | المواد الدراسية</title>
        <meta name="description" content={homeData?.info?.body} />
      </Helmet>
    <div className='container py-5'>
      <div
        className='teacher_section container '
        style={{
          minHeight: "90vh",
        }}
      >
        <div className='lottie_animation'>
          <Player
            autoplay
            loop
            src={lottie} // You can also use a URL here, like 'https://assets.lottiefiles.com/packages/lf20_yourfile.json'
            style={{transform: "scale(1.4)"}}
          />
          <Player
            autoplay
            loop
            src={lottie} // You can also use a URL here, like 'https://assets.lottiefiles.com/packages/lf20_yourfile.json'
            style={{transform: "scale(1.4)"}}
          />
        </div>

        <div className='row align-items-center mb-5 '>
          <div className='col-md-6 col-12 mb-sm-4'>
            <Typo
              positon={"center"}
              fw={"bolder"}
              color={"#4E2F7D"}
              variant={"h2"}
            >
              كل المواد
            </Typo>
          </div>
          <div className='col-md-6  col-12 '>
            {!loading && (
              <SearchInput
                placeholder={"بحث عن مادة"}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            )}
          </div>
        </div>

        {loading ? (
          <div
            className='p-5'
            style={{
              minHeight: "90vh",
            }}
          >
            <Spinner variant={"io"} />
          </div>
        ) : originalData &&
          originalData.length >= 1 &&
          Array.isArray(originalData) ? (
          subjects.length >= 1 ? (
            <div style={{}} className='subjects_section_content'>
              {subjects?.map((item, index) => {
                return (
                  <Tilt className='w-100' key={index} data={item}>
                    <SubjectCard
                      onClick={() => navigate(`/subjects/${item.subject_id}`)}
                      item={item}
                    />
                  </Tilt>
                );
              })}
            </div>
          ) : (
            <Typo
              variant={"h2"}
              color={"var(--vio-1)"}
              fw={"bolder"}
              positon={"center"}
              className=''
            >
              لا يوجد نتائج....
            </Typo>
          )
        ) : (
          <EmptyContent size={"large"} text={"لا يوجد مواد إلى الآن!"} />
        )}
      </div>
    </div>
    </>
  );
};

export default Subjects;
