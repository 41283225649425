


import React from 'react'
import "./style.css"
import Logo from "../../assets/images/Sahla_logo.png";

const SplashScreen = () => {
  return (
    <div className='splash_screen'>
      <div className="splash_logo scale-up-center">
        <img width={80} src={Logo} alt="" />
      </div>
    </div>
  )
}

export default SplashScreen
