import React, {useState} from "react";
import "./style.css";
import GlassyBackground from "../../../../utils/GlassyBackground/GlassyBackground";
import {exitModal, eyeOn} from "../../../../assets/svgIcons";
import Tooltip from "../../../../utils/Tooltip/Tooltip";
import Modal from "../../../../utils/Modal/Modal";
import img from "../../../../assets/images/std_1.jpeg"
import errorImage from "../../../../assets/images/errorImage.jpg"


const TeacherImage = ({image ,modal , setModal}) => {


  const handleImageError = (event) => {
    event.target.src = errorImage; 

  }

  return (
    <>
    
      <div className='teacher_img'>
        <img loading="lazy" onError={handleImageError} src={image} alt='' />
        <div className='d-flex teacher_image_show_btn' onClick={()=> setModal(true)}>
          <Tooltip position={"bottom"} title={"عرض الصورة"}>

          <GlassyBackground className={"p-1 pointer  hover_scale"} blur={5} bgColor={"rgba(88, 5, 105, 0.26)"}>
            <div className='text-white fs-6' onClick={() => setModal(true)}>
              {eyeOn}
            </div>
          </GlassyBackground>
          </Tooltip>
        </div>
     
      </div>



    </>
  );
};

export default TeacherImage;
