import "./App.css";
import DefaultLayout from "./layouts/DefaultLayout/DefaultLayout";
import AOS from "aos";
import AppRoutes from "./Routes/Routes";
import "aos/dist/aos.css";
import "antd/dist/reset.css";
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import toast, {Toaster} from "react-hot-toast";
import SplashScreen from "./components/SplashScreen/SplashScreen";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AOS.init({
      duration: 500,
      once: true,
    });

    // Simulate a loading period
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Adjust the time as needed

    return () => clearTimeout(timer);
  }, []);
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
      });
    }, 150);
  }, [location.pathname]);

  if (loading) {
    return <SplashScreen />;
  }

  return (
    <>
      <DefaultLayout>
        <AppRoutes />
      </DefaultLayout>
      <Toaster
        containerClassName='toastCon'
        position='bottom-right'
        reverseOrder={false}
      />
    </>
  );
}

export default App;
