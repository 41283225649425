import React from "react";

import "./style.css";
import "./swiper.css";
import {Swiper, SwiperSlide} from "swiper/react";

// Import Swiper styles
import "swiper/css/navigation";
import "swiper/css";

// import "../../HomePage/NotiRump/swiperStyle.css";
import "swiper/css/pagination";

// import required modules
import {EffectCoverflow, Navigation, Pagination} from "swiper/modules";

import "swiper/css/effect-coverflow";

import image from "../../assets/images/teacher__1.png";
import { arrowLeft, arrowRight } from "../../assets/svgIcons";

const SwiperEffect = ({children}) => {
  return (
    <>
      <Swiper
        loopFillGroupWithBlank={true}
        // ------------------------
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        // slidesPerView={"auto"}
        // pagination={true}
        spaceBetween={40}
        loop={true}
        navigation={{
          nextEl: ".custom-next",
          prevEl: ".custom-prev",
        }}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 1,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 1,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 1,
          },
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        coverflowEffect={{
          rotate: 10,
          stretch: 50,
          depth: 200,
          modifier: 1,
        }}
        className='mySwiper'
      >
        <SwiperSlide className='swiper-slide'>
          <div className='food_card'>
            <div
              className='food_slide'
              style={{
                paddingInline: "40px",
              }}
            >
              <img src={image} alt='' />
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide className='swiper-slide'>
          <div className='food_card'>
            <div
              className='food_slide'
              style={{
                paddingInline: "40px",
              }}
            >
              <img src={image} alt='' />
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide className='swiper-slide'>
          <div className='food_card'>
            <div
              className='food_slide'
              style={{
                paddingInline: "40px",
              }}
            >
              <img src={image} alt='' />
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide className='swiper-slide'>
          <div className='food_card'>
            <div
              className='food_slide'
              style={{
                paddingInline: "40px",
              }}
            >
              <img src={image} alt='' />
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide className='swiper-slide'>
          <div className='food_card'>
            <div
              className='food_slide'
              style={{
                paddingInline: "40px",
              }}
            >
              <img src={image} alt='' />
            </div>
          </div>
        </SwiperSlide>

        <div className='custom-navigation pet_food'>
          <div className='custom-prev'>{arrowLeft}</div>
          <div className='custom-next'>{arrowRight}</div>
        </div>

        {/* <div className="custom-prev">{arrowLeft}</div>
    <div className="custom-next">{arrowRight}</div> */}
      </Swiper>
    </>
  );
};

export default SwiperEffect;
