import React from "react";
import "./style.css";
import HomeBanner from "../../components/HomeComponents/HomeBanner/HomeBanner";
import LogosSection from "./../../components/HomeComponents/LogosSection/LogosSection";
import WelcomeSection from "../../components/HomeComponents/WelcomeSection/WelcomeSection";
import MarqueeSection from "../../components/HomeComponents/MarqueeSection/MarqueeSection";
import TeachersSection from "../../components/HomeComponents/TeachersSection/TeachersSection";
import EducationForEveryone from "../../components/HomeComponents/EducationForEveryone/EducationForEveryone";
import CurrentEvents from "./../../components/HomeComponents/CurrentEvents/CurrentEvents";
import {Player} from "@lottiefiles/react-lottie-player";
import lottie from "../../assets/lotties/Animation - 1725796118810.json";
import { useMediaQuery } from "../../customHooks/useMediaQueries";
import { Helmet } from 'react-helmet';
import UseGETHomeData from './../../customHooks/UseGETHomeData';

const HomePage = () => {



  const {homeData, getHomeData, setHomeData, loading} =  UseGETHomeData()
  




  return (
    <>
      <Helmet>
        <title>سهلة </title>
        <meta name="description" content={homeData?.info?.body} />
      </Helmet>
    <div className='home_page'>
      <HomeBanner />
      <EducationForEveryone  />
      {/* <LogosSection /> */}
      <div className='py-0 py-md-4'></div>
      {/* <WelcomeSection /> */}
      <MarqueeSection />
      <TeachersSection />
      <CurrentEvents />
    </div>
    </>

  );
};

export default HomePage;
