import React from "react";
import cx from "classnames";
import "./style.css"
const Spinner = ({ variant}) => {
  if (variant == "io") {
    return (
      <>
        <div className="loaderSpinner"></div>
      </>
    );
  }

  return (
    <div
      className={cx("spinner-border", variant || "text-primary")}
      role='status'
    >
      <span className='visually-hidden'>Loading...</span>
    </div>
  );
};

export default Spinner;
