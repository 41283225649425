import React from "react";
import { screen } from '@testing-library/react';
import "./style.css"
import UseGETHomeData from "../../../customHooks/UseGETHomeData";
const EducationForEveryone = () => {
  const data = [
    {
      icon: icons.flag,
      title: "الإرشاد الحياتي",
      desc: "احصل على بعض الشفاء الروحي والتوجيه لمسيرتك المهنية المستقبلية واتجاهاتك المهنية.",
    },
    {
      icon: icons.cog,
      title: " تطوير الذات",
      desc: "تطوير المهارات اللازمة لمهنة التخصصات المختلفة بما في ذلك علوم الكمبيوتر وكفاءة اللغة.",
    },
    {
      icon: icons.question,
      title: "الإرشاد الحياتي",
      desc: "قم بالحجز مع مستشارنا الخاص لمعرفة ما هو الأفضل لمهنة أحلامك.",
    },
    {
      icon: icons.screen,
      title: "التعلم عن بعد",
      desc: "تعلم من أي مكان في العالم على سطح المكتب أو الجهاز اللوحي أو الهاتف المحمول مع اتصال بالإنترنت.",
    },
  ];

  const {homeData, getHomeData, setHomeData, loading} = UseGETHomeData()



  return (
    <div className='edu_for_everyone container'>
      {
        homeData?.features?.map((item , index)=>{
          return(
          <div className='efe_card' key={index}>
            <div className='efe_card_icon '>
              <img src={item.icon} alt="" />
            </div>
            <div className='efe_card_title'>{item.title}</div>
            <div className='efe_card_desc text-center'>{item.body}</div>
          </div>

          )
        })
      }
    </div>
  );
};

export default EducationForEveryone;

const icons = {
  flag: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 256 256'
    >
      <path
        fill='currentColor'
        d='M42.76 50A8 8 0 0 0 40 56v168a8 8 0 0 0 16 0v-44.23c26.79-21.16 49.87-9.75 76.45 3.41c16.4 8.11 34.06 16.85 53 16.85c13.93 0 28.54-4.75 43.82-18a8 8 0 0 0 2.76-6V56a8 8 0 0 0-13.27-6c-28 24.23-51.72 12.49-79.21-1.12C111.07 34.76 78.78 18.79 42.76 50M216 172.25c-26.79 21.16-49.87 9.74-76.45-3.41c-25-12.35-52.81-26.13-83.55-8.4V59.79c26.79-21.16 49.87-9.75 76.45 3.4c25 12.35 52.82 26.13 83.55 8.4Z'
      />
    </svg>
  ),
  cog: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 512 512'
    >
      <path
        fill='currentColor'
        d='M245.151 168a88 88 0 1 0 88 88a88.1 88.1 0 0 0-88-88m0 144a56 56 0 1 1 56-56a56.063 56.063 0 0 1-56 56'
      />
      <path
        fill='currentColor'
        d='m464.7 322.319l-31.77-26.153a193.1 193.1 0 0 0 0-80.332l31.77-26.153a19.94 19.94 0 0 0 4.606-25.439l-32.612-56.483a19.936 19.936 0 0 0-24.337-8.73l-38.561 14.447a192 192 0 0 0-69.54-40.192l-6.766-40.571A19.936 19.936 0 0 0 277.762 16H212.54a19.94 19.94 0 0 0-19.728 16.712l-6.762 40.572a192 192 0 0 0-69.54 40.192L77.945 99.027a19.94 19.94 0 0 0-24.334 8.731L21 164.245a19.94 19.94 0 0 0 4.61 25.438l31.767 26.151a193.1 193.1 0 0 0 0 80.332l-31.77 26.153A19.94 19.94 0 0 0 21 347.758l32.612 56.483a19.94 19.94 0 0 0 24.337 8.73l38.562-14.447a192 192 0 0 0 69.54 40.192l6.762 40.571A19.94 19.94 0 0 0 212.54 496h65.222a19.936 19.936 0 0 0 19.728-16.712l6.763-40.572a192 192 0 0 0 69.54-40.192l38.564 14.449a19.94 19.94 0 0 0 24.334-8.731l32.609-56.487a19.94 19.94 0 0 0-4.6-25.436m-50.636 57.12l-48.109-18.024l-7.285 7.334a159.96 159.96 0 0 1-72.625 41.973l-10 2.636L267.6 464h-44.89l-8.442-50.642l-10-2.636a159.96 159.96 0 0 1-72.625-41.973l-7.285-7.334l-48.117 18.024L53.8 340.562l39.629-32.624l-2.7-9.973a160.9 160.9 0 0 1 0-83.93l2.7-9.972L53.8 171.439l22.446-38.878l48.109 18.024l7.285-7.334a159.96 159.96 0 0 1 72.625-41.973l10-2.636L222.706 48H267.6l8.442 50.642l10 2.636a159.96 159.96 0 0 1 72.625 41.973l7.285 7.334l48.109-18.024l22.447 38.877l-39.629 32.625l2.7 9.972a160.9 160.9 0 0 1 0 83.93l-2.7 9.973l39.629 32.623Z'
      />
    </svg>
  ),
  question: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='0.63em'
      height='1em'
      viewBox='0 0 320 512'
    >
      <path
        fill='currentColor'
        d='M80 160c0-35.3 28.7-64 64-64h32c35.3 0 64 28.7 64 64v3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1a87.98 87.98 0 0 0-40.4 74v1.4c0 17.7 14.3 32 32 32s32-14.3 32-32v-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7v-3.6c0-70.7-57.3-128-128-128h-32C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32m80 320a40 40 0 1 0 0-80a40 40 0 1 0 0 80'
      />
    </svg>
  ),
  screen: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 15 15'
    >
      <path
        fill='none'
        stroke='currentColor'
        d='M4 14.5h7M.5 2.5v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1h-12a1 1 0 0 0-1 1Z'
      />
    </svg>
  ),
};
