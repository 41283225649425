import React from "react";
import "./style.css";
import {
  social_facebook,
  social_tiktok,
  social_udemy,
  social_x,
  social_youtube,
} from "../../../assets/svgIcons";
import {social_instagram} from "./../../../assets/svgIcons";
import GlassyBackground from "./../../../utils/GlassyBackground/GlassyBackground";
import Tilt from "react-parallax-tilt";

const TeacherScocials = ({data}) => {
  return (
    <div className='container flex '>
      {data.facebook ||
      data.instegram ||
      data.twitter ||
      data.youtube ||
      data.tiktok ||
      data.udemy ? (
        <ul className='example-2 shadow-lg rainbow'>
          {data.facebook && (
            <Tilt>
              <li className='icon-content'>
                <a
                  href={data.facebook}
                  target='_blank'
                  aria-label='Facebook'
                  data-social='facebook'
                >
                  <div className='filled' />
                  {social_facebook}
                </a>
                <div className='tooltip'>Facebook</div>
              </li>
            </Tilt>
          )}

          {data.instegram && (
            <Tilt>
              <li className='icon-content'>
                <a
                  href={data.instegram}
                  aria-label='Instagram'
                  data-social='instagram'
                >
                  <div className='filled' />
                  {social_instagram}
                </a>
                <div className='tooltip'>Instagram</div>
              </li>
            </Tilt>
          )}

          {data.twitter && (
            <Tilt>
              <li className='icon-content'>
                <a href={data.twitter} aria-label='X' data-social='x'>
                  <div className='filled' />
                  {social_x}
                </a>
                <div className='tooltip'>X_twitter</div>
              </li>
            </Tilt>
          )}

          {data.youtube && (
            <Tilt>
              <li className='icon-content'>
                <a
                  href={data.youtube}
                  aria-label='Youtube'
                  data-social='youtube'
                >
                  <div className='filled' />
                  {social_youtube}
                </a>
                <div className='tooltip'>Youtube</div>
              </li>
            </Tilt>
          )}

          {data.udemy && (
            <Tilt>
              <li className='icon-content'>
                <a href={data.udemy} aria-label='Udemy' data-social='udemy'>
                  <div className='filled' />
                  {social_udemy}
                </a>
                <div className='tooltip'>Udemey</div>
              </li>
            </Tilt>
          )}
          {data.tiktok && (
            <Tilt>
              <li className='icon-content'>
                <a href={data.tiktok} aria-label='Tiktok' data-social='tiktok'>
                  <div className='filled' />
                  {social_tiktok}
                </a>
                <div className='tooltip'>TikTok</div>
              </li>
            </Tilt>
          )}
        </ul>
      ) : (
        ""
      )}
    </div>
  );
};

export default TeacherScocials;
