import React from "react";
import "./style.css";
import AnimatedVidButton from "../../../utils/Buttons/AnimatedVidButton/AnimatedVidButton";

import thumbnailPlaceholder from "../../../assets/images/Video-Placeholder.jpg";

const VidCard = ({data  , teacher}) => {
  const thumpname = data?.thumb;

  function getYouTubeThumbnail(videoUrl) {
    // Extract the video ID from the URL
    const videoId = videoUrl.split("v=")[1]?.split("&")[0];

    if (!videoId) {
      return teacher?.image;
    }

    // Construct the thumbnail URL
    const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
    return thumbnailUrl;
  }

  return (
    <div className='vid_card shadow'>
      <div className='vid-card-image'>
        <img src={getYouTubeThumbnail(data.link)} alt='' />
      </div>
      <div className='vid_card_content'>{data?.title}</div>
      <div className='vid_card_overlay'>
        <AnimatedVidButton onClick={()=> window.open(data.link , "_blank")}  />
      </div>
    </div>
  );
};
export default VidCard;
