import React, {useEffect, useState} from "react";
import TeacherBanner from "../../components/TeacherPageComponents/TeacherBanner/TeacherBanner";
import TeacherInfo from "../../components/TeacherPageComponents/TeacherInfo/TeacherInfo";
import "./style.css";
import TeacherScocials from "../../components/TeacherPageComponents/TeacherScocials/TeacherScocials";
import SwiperEffect from "../../utils/swiper with effect/SwiperEffect";
import PetFood from "../../utils/PetFood/PetFood";
import BestStudetnsSection from "../../components/TeacherPageComponents/BestStudetnsSection/BestStudetnsSection";
import VideoSection from "../../components/TeacherPageComponents/VideoSection/VideoSection";
import TimeLineSection from "./../../components/TeacherPageComponents/TimeLineSection/TimeLineSection";
import BranchesSection from "../../components/TeacherPageComponents/BranchesSection/BranchesSection";
import placeholder from "../../assets/images/manPlaceholder.jpeg";
import {Navigate, useLocation, useParams} from "react-router-dom";
import {teachers} from "../../data/teachersData";
import axios from "axios";
import {BASE_URL} from "../../API/BASE_URL";
import toast from "react-hot-toast";
import Spinner from "./../../utils/Spinner/Spinner";
import {Helmet} from "react-helmet";
import UseGETHomeData from "../../customHooks/UseGETHomeData";
const TeacherPage = () => {
  const [teacherData, setteahcerData] = useState({});
  const {id} = useParams();

  const [loading, setLoading] = useState(false);
  const getTeacherData = async () => {
    setLoading(true);

    await axios
      .get(`${BASE_URL}/info/select_teacher.php`)
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          const result = res?.data.message;
          const filteredTeacher = result.find((t) => t.teacher_id == id);
          setteahcerData(filteredTeacher);
        } else {
          toast.error("There is a problem in teachers section!");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getTeacherData();
  }, []);

  const {
    homeData,
    getHomeData,
    setHomeData,
    loading: homeLoading,
  } = UseGETHomeData();

  if (!teacherData) {
    return <Navigate to={"/"} replace />;
  }

  return(
    <>
      {!loading && (
        <Helmet>
          <title>سهلة | {teacherData?.name ? teacherData?.name : ""} </title>
          <meta name='description' content={homeData?.info?.body} />
        </Helmet>
      )}
      <div className='teacher_page'>
        {loading ? (
          <div
            className='d-flex align-items-center justify-content-center'
            style={{minHeight: "90vh"}}
          >
            <Spinner variant={"io"} />
          </div>
        ) : (
          <>
            <TeacherBanner
              image={teacherData?.image || placeholder}
              banner={teacherData?.cover}
            />
            <TeacherInfo data={teacherData} />
            <TeacherScocials data={teacherData} />
            <BestStudetnsSection data={teacherData} />
            <VideoSection data={teacherData} />
            <BranchesSection data={teacherData} />
            <TimeLineSection data={teacherData} />
          </>
        )}
      </div>
    </>
  );
};

export default TeacherPage;
