

import React from 'react'
import "./style.css"
import { locationIcon } from '../../../assets/svgIcons'
const BranchCard = ({ data , onClick}) => {
  return (
    <div onClick={onClick} className='branch_card'>
        <div className="branch_card_content">
            {data.title}
        </div>
        <div className=" fs-6 text-light branch_card_content_location d-flex align-items-center gap-3 ">
        {locationIcon} {data.description} 
        </div>
    </div>
  )
}

export default BranchCard
