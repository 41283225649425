import React, {useState} from "react";
import "./style.css";
import GlassyBackground from "../../../utils/GlassyBackground/GlassyBackground";
import ShowMoreButton from "../../../utils/Buttons/ShowMoreButton/ShowMoreButton";
import {useArabicFormattedDate} from "./../../../customHooks/dateFormats";
import Modal from "../../../utils/Modal/Modal";
import Typo from "../../../utils/Typo/Typo";
import CountdownTimer from "../../../utils/CountDownTimer/CountDownTimer";
const EventCard = ({data}) => {
  const [modal, setModal] = useState(false);

  return (
    <>
      <div className='event_card_container'>
        <div className='event_card '>
          <div className='event_timer'>
            <CountdownTimer targetTime={data?.time} />
          </div>
          <div className='event_card_content '>
            <h5 className='event_card_date d-flex '>
              {useArabicFormattedDate(data?.time)} - {data.address}
            </h5>
            <h2 className='event_card_title'> {data?.title} </h2>
          </div>
          <div className='event_card_showmore'>
            <ShowMoreButton onClick={() => setModal(true)} />
          </div>
        </div>
      </div>
      <Modal
        animation={true}
        showCloseBtn={true}
        overlay={true}
        size={"80%"}
        show={modal}
        onClose={() => setModal(false)}
        children={
          <div classname='px-5'>
            <GlassyBackground className='event_card_container inmodal'>
              <div className='event_card '>
                <div className='event_card_content'>
                  <h5 className='event_card_date'>
                    {useArabicFormattedDate(data?.time)} - {data.address}
                  </h5>
                  <div className=''>
                    <Typo
                      fit={true}
                      color={"#fff"}
                      p={"5px 10px"}
                      br={"10px"}
                      fw={"bolder"}
                      bg={"var(--vio-2)"}
                    >
                      <CountdownTimer targetTime={data?.time} />
                    </Typo>
                  </div>
                  <h2 className='event_card_title'> {data?.title} </h2>

                  <Typo variant={"lg"} lh={"2"}>
                    {data?.description}
                  </Typo>
                </div>
              </div>
            </GlassyBackground>
          </div>
        }
      />
    </>
  );
};

export default EventCard;
