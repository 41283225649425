import React from "react";
import "./style.css";
import IconButton from "./../../../utils/Buttons/IconButton/IconButton";
import {
  social_facebook,
  social_instagram,
  social_tiktok,
  social_x,
  social_youtube,
  userIconFill,
} from "../../../assets/svgIcons";
import Tooltip from "../../../utils/Tooltip/Tooltip";
import Typo from "../../../utils/Typo/Typo";
import errorImage from "../../../assets/images/errorImage.jpg"

const TeacherCard = ({data, onClick}) => {


  const handleImageError = (event) => {
    event.target.src = errorImage; // Set a fallback image or handle the error
  }


  return (
    <div onClick={onClick} className={"teacher_card"}>
        <div className='teacher_card_img shadow-lg'>
          <img onError={handleImageError} src={data.image} alt='' />
        </div>

        <div className='teacher_card_content m-0 p-0'>
          <div className='p-0 m-0 my-3 '>
            <h3 className=' teacher_name m-0 p-0 text-center text-md-end'>
              {data.name || "..."}
            </h3>
            <h5 className=' teacher_sub m-0 p-0 mt-3 text-center text-md-end'>
              {data.subject_name || "..."}
            </h5>
          </div>

          <div className='teacher_card_socilas '>
            {data.facebook && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(data.facebook, "_blank");
                }}
                icon={social_facebook}
                bgColor={"#3B5998"}
              />
            )}
            {data.instegram && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();

                  window.open(data.instegram, "_blank");
                }}
                icon={social_instagram}
                bgColor={"#B93877"}
              />
            )}
            {data.tiktok && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();

                  window.open(data.tiktok, "_blank");
                }}
                icon={social_tiktok}
                bgColor={"black"}
              />
            )}
            {data.twitter && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();

                  window.open(data.twitter, "_blank");
                }}
                icon={social_x}
                bgColor={"#222"}
              />
            )}
            {data.youtube && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();

                  window.open(data.youtube, "_blank");
                }}
                icon={social_youtube}
                bgColor={"#d01d1d"}
              />
            )}
          </div>
        </div>

    </div>
  );
};

export default TeacherCard;
