import React from "react";
import "./style.css";
import cx from "classnames"
const GlassyBackground = ({
  blur,
  className,
  saturate,
  fit,
  bgColor,
  radius,
  children,
  border,
  solidWhite,
}) => {
  return (
    <div
    className={cx("glassy_bg" , className)}
      style={
        solidWhite
        ? {
          backgroundColor: "transparent",
          width:fit ? "max-content":"auto"
            }
          : {
              backdropFilter: `blur(${blur || 10}px) saturate(${
                saturate || 100
              }%)`,
              WebkitBackdropFilter: `blur(${blur || 10}px) saturate(${
                saturate || 100
              }%)`,
              backgroundColor: bgColor || "rgba(124, 128, 141, 0.3)",
              borderRadius: radius || "12px",
              border: border || "1px solid rgba(255, 255, 255, 0.125)",
              width:fit ? "max-content":"auto"
            }
      }
      
    >
      {children}
    </div>
  );
};

export default GlassyBackground;
