

import React, { useState } from 'react'
import Header from './../Header/Header';
import Footer_1 from '../Footer_1/Footer_1';
import LeftSide from './../LeftSideBar/LeftSide';

const DefaultLayout = ({children}) => {
  const [side , setSide] = useState(false)
  return (
    <div className='default-layout'>
      <Header openSide={side} setOpenSide={setSide}  />
      {children}
      <Footer_1 />
      <LeftSide open={side} setOpen={setSide}/>
    </div>
  )
}

export default DefaultLayout
