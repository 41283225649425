import axios from "axios";
import React, {useEffect, useState} from "react";
import { BASE_URL } from "../API/BASE_URL";
import toast from "react-hot-toast";

const UseGETHomeData = () => {
  const [homeData, setHomeData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getHomeData = async () => {
    setLoading(true);

    await axios
      .get(`${BASE_URL}/info/select_site_info.php`)
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setHomeData(res?.data?.message);
        } else {
          toast.error("There is a problem in subjects");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getHomeData();
  }, []);

  return {homeData, getHomeData, setHomeData, loading};
};

export default UseGETHomeData;
