import React, {useEffect} from "react";
import "./style.css";
import VidCard from "../../Cards/VidCard/VidCard";
import SectionTitle from "../../../utils/SectionTitle/SectionTitle";
import Tilt from "react-parallax-tilt";
import {useMediaQuery} from "../../../customHooks/useMediaQueries";
import { videoIcon } from "../../../assets/svgIcons";
const VideoSection = ({data}) => {
  const smallScreens = useMediaQuery("(max-width:768px)");

  useEffect(() => {
    console.log(data);
  }, [data]);

  return data.videos &&
    data.videos.length >= 1 &&
    Array.isArray(data.videos) ? (
    <div className='container'>
      <SectionTitle
        color={"#4E2F7D"}
        my={"30px"}
        fw={"bolder"}
        variant={smallScreens ? "h4" : "h1"}
        title={<span> {videoIcon} الفيديوهات </span>}
      />
      <div className='vids_content'>
        {data?.videos?.map((item, index) => {
          return (
            <Tilt
              key={index}
              glareEnable={true}
              glareMaxOpacity={0.8}
              glareColor='#ffffff'
              glarePosition='bottom'
              glareBorderRadius='20px'
            >
              <VidCard data={item} teacher={data} />
            </Tilt>
          );
        })}
      </div>
    </div>
  ) : (
    ""
  );
};

export default VideoSection;
