import React, {useState} from "react";
import "./style.css";
import SectionTitle from "../../../utils/SectionTitle/SectionTitle";
import BranchCard from "../../Cards/BranchCard/BranchCard";
import Tilt from "react-parallax-tilt";
import BranchLocationModal from "./BranchLocationModal/BranchLocationModal";
import { useMediaQuery } from "../../../customHooks/useMediaQueries";
import { branchIcon } from "../../../assets/svgIcons";
const BranchesSection = ({data}) => {
  const tiltOption = {
    glareEnable: true,
    glareMaxOpacity: 0.8,
    glareColor: "white",
    glarePosition: "bottom",
    glareBorderRadius: "12px",
  };

  const [mapModal, setMapModal] = useState(false);
  const [rowData, setRowData] = useState(null);

  const branches = [
    {
      id: 1,
      place: "جامعة القاهرة",
      description:
        "واحدة من أقدم وأكبر الجامعات في مصر، تقدم برامج دراسية في الفيزياء ضمن كلية العلوم.",
      map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1727.1745283382904!2d31.211385561316042!3d30.026842243733135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145846db01520851%3A0xbd9781bf40115c5d!2z2KzYp9mF2LnYqSDYp9mE2YLYp9mH2LHYqQ!5e0!3m2!1sar!2seg!4v1725525279716!5m2!1sar!2seg",
    },

    {
      id: 2,
      place: "جامعة عين شمس",
      description:
        "جامعة حكومية كبيرة في القاهرة تقدم برامج في الفيزياء والهندسة.",
      map: "https://www.google.com/maps/place/%D8%AC%D8%A7%D9%85%D8%B9%D8%A9+%D8%B9%D9%8A%D9%86+%D8%B4%D9%85%D8%B3%E2%80%AD/@30.0766493,31.2871209,17z/data=!3m1!4b1!4m6!3m5!1s0x14581aa0476faf15:0x61a1e5a21354321a!8m2!3d30.0766493!4d31.284546!16zL20vMDZibms1?entry=ttu&g_ep=EgoyMDI0MDkwMi4xIKXMDSoASAFQAw%3D%3D",
    },
    {
      id: 3,
      place: "جامعة الإسكندرية",
      description:
        "جامعة حكومية تقع في الإسكندرية وتقدم برامج متقدمة في الفيزياء والعلوم.",
      map: "https://www.google.com/maps/search/%D8%AC%D8%A7%D9%85%D8%B9%D8%A9+%D8%A7%D9%84%D8%A5%D8%B3%D9%83%D9%86%D8%AF%D8%B1%D9%8A%D8%A9%E2%80%AD/@31.2108051,29.9648047,14z/data=!3m1!4b1?entry=ttu&g_ep=EgoyMDI0MDkwMi4xIKXMDSoASAFQAw%3D%3D",
    },
    {
      id: 4,
      place: "الجامعة الأمريكية بالقاهرة",
      description:
        "جامعة خاصة توفر برامج علمية متطورة في الفيزياء ومواد الهندسة.",
      map: "https://www.google.com/maps/search/%D8%A7%D9%84%D8%AC%D8%A7%D9%85%D8%B9%D8%A9+%D8%A7%D9%84%D8%A3%D9%85%D8%B1%D9%8A%D9%83%D9%8A%D8%A9+%D8%A8%D8%A7%D9%84%D9%82%D8%A7%D9%87%D8%B1%D8%A9%E2%80%AD/@30.0540328,31.4800647,12z/data=!3m1!4b1?entry=ttu&g_ep=EgoyMDI0MDkwMi4xIKXMDSoASAFQAw%3D%3D",
    },
    {
      id: 5,
      place: "المعهد العالي للتكنولوجيا في بنها",
      description:
        "معهد تكنولوجي يوفر برامج دراسية في الفيزياء ضمن مناهج الهندسة.",
      map: "https://www.google.com/maps/place/%D8%A7%D9%84%D9%85%D8%B9%D9%87%D8%AF%E2%80%AD/@30.4653814,31.1876577,17z/data=!4m6!3m5!1s0x14f7df6b0a31d7e7:0x5311470719317cca!8m2!3d30.4653814!4d31.1850828!16s%2Fg%2F11qp8k6l7x?entry=ttu&g_ep=EgoyMDI0MDkwMi4xIKXMDSoASAFQAw%3D%3D",
    },
  ];

  const onClickBranch = (branch) => {
    setRowData(branch);
    setMapModal(true);
  };
  const smallScreens  = useMediaQuery("(max-width:768px)");

  return (

    
      data.teacher_places && data.teacher_places.length >=1 && Array.isArray(data.teacher_places) ? 
    <div className='branches_section container'>
      <SectionTitle
        color={"#4E2F7D"}
        my={"30px"}
        fw={"bolder"}
        variant={smallScreens ? "h4":"h3"}
        title={<span> {branchIcon} الفروع </span>}
      />

      <div className='branches_container'>
        {data?.teacher_places?.map((item, index) => {
          return (
            <Tilt className='branch_tilt' key={index} {...tiltOption}>
              <BranchCard data={item} onClick={() => onClickBranch(item)}/>
            </Tilt>
          );
        })}
      </div>
      <BranchLocationModal
        data={rowData}
        open={mapModal}
        setopen={setMapModal}
      />
    </div>

    :""
    

  );
};

export default BranchesSection;
