import React, { useEffect, useState } from "react";
import "./style.css";
import StyledBackground from "../../utils/StyledBackground/StyledBackground";
import Tilt from "react-parallax-tilt";

import svg from "../../assets/svgs/Learning-bro.svg";
import Typo from "./../../utils/Typo/Typo";
import { useMediaQuery } from "../../customHooks/useMediaQueries";
import axios from "axios";
import { BASE_URL } from "../../API/BASE_URL";
import toast from "react-hot-toast";
import Spinner from './../../utils/Spinner/Spinner';
import { social_facebook, social_instagram, social_x, social_youtube } from "../../assets/svgIcons";
import Logo from "../../assets/svgs/logo";
import IconButton from "../../utils/Buttons/IconButton/IconButton";
import UseGETHomeData from "../../customHooks/UseGETHomeData";
import { Helmet } from "react-helmet";
const AboutUs = () => {

  const smallScreen = useMediaQuery("(max-width: 768px)");


  // const [loading, setLoading] = useState(false);
  // const [bannerData , setBannerData] = useState([])
  // const getBannerData = async () => {
  //   setLoading(true);


  //   await axios
  //     .get(`${BASE_URL}/info/select_site_info.php`)
  //     .then((res) => {
  //       console.log(res);
  //       if (res.data.status == "success") {
  //         setBannerData(res?.data?.message);
  //       } else {
  //         toast.error("There is a problem in subjects");
  //       }
  //     })
  //     .catch((e) => console.log(e))
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  // useEffect(() => {
  //   getBannerData();
  // }, []);


  const {homeData, getHomeData, setHomeData, loading:homeloading} = UseGETHomeData()



  return (
    <>
    <Helmet>
        <title>سهلة | معلومات عنا</title>
        <meta name="description" content={homeData?.info?.body} />
      </Helmet>
    <StyledBackground wave={true}>
      <div className='home_banner '>
        <div className='row container'>
          <div data-aos="fade-left" className='col-12  banner_content'>
            {
              homeloading ? <div className="my-5"><Spinner variant={"io"} /> </div>: 

              <>
                <Typo positon={smallScreen ? "center" :"start"} fw={"bolder"} variant={"h2"} color={"light"}>
              {homeData?.info?.title}
            </Typo>
            <Typo  positon={smallScreen ? "center" :"start"} width={ smallScreen ? "100%":"80%"} variant={"h5"} mt={"20px"} color={"light"}>
             {homeData?.info?.body}
            </Typo>
           
            <Typo
              mt={smallScreen ? "30px":"70px"}
              fw={"bolder"}
              variant={"h5"}
              color={"rgb(226, 216, 84)"}
            >
              {" "}
              {homeData?.info?.description}
            </Typo></>
            }
          </div>
          <div className='footer1_header'>
            
            <div className='socials d-flex align-items-center gap-4 justify-content-center'>
              <IconButton onClick={()=> window.open("https://www.facebook.com/bio.lovers.20" , "_blank")} icon={social_facebook} bgColor={"#1877F2"} />
              {/* <IconButton
                icon={social_instagram}
                bgColor={"rgb(191, 35, 126)"}
              />
              <IconButton icon={social_x} bgColor={"black"} />
              <IconButton icon={social_youtube} bgColor={"rgb(189, 21, 21)"} /> */}
            </div>
          </div>

          {/* <div data-aos="fade-right" className='col-12 col-md-4 text-start '>
            <Tilt gyroscope={true}>
              <img src={svg} alt='' />
            </Tilt>
          </div> */}
        </div>
      </div>
    </StyledBackground>
    </>

  );
};

export default AboutUs;
