import React, {useEffect, useState} from "react";
import "./style.css";
import {arrowLeft} from "../../assets/svgIcons";

import {Link, useLocation, useNavigate} from "react-router-dom";
import MenuSwitch from "./../../utils/menuSwitch/MenuSwitch";
import StyledBackground from "./../../utils/StyledBackground/StyledBackground";
import GlassyBackground from "./../../utils/GlassyBackground/GlassyBackground";
import sahla_white from "../../assets/images/Sahla_logo_white.png"
const LeftSide = ({open, setOpen}) => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    group_name: null,
    subjects: [],
    subject_levels: [],
    level_topics: [],
    level_topic_name: "",
  });

  const [steps, setSteps] = useState([]);
  const handleAppend = (value) => {
    setSteps([...steps, value]);
  };

  const handleRemove = () => {
    const newSteps = steps.slice(0, -1);
    setSteps(newSteps);
  };

  useEffect(() => {
    if (open == false) {
      handleRemove();
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [open]);

  const onCloseLiftSide = (e) => {
    if (e) {
      setOpen(e.target.checked);
      if (!e.target.checked) {
      }
      return;
    }
    handleRemove();
  };

  const goTo = (link) => {
    navigate(link);
    setOpen(false);
  };

  const location = useLocation();

  const routes = [
    {
      title: "الرئيسية",
      pathname: "/",
    },
    {
      title: "معلومات عنا",
      pathname: "/aboutus",
    },
    {
      title: "المعلمون",
      pathname: "/all-teachers",
    },
    {
      title: "المواد الدراسية",
      pathname: "/subjects",
    },
    {
      title: "الأحداث الجارية",
      pathname: "/all-events",
    },

  ];

  return (
    <>
      <div className={`side_nav_container ${open ? "open" : ""}`}>
        <div className='overlay' onClick={() => setOpen(false)}></div>
        <div className={`side_nav_contnet ${open ? "open" : ""}`}>
        <div className="side_logo">

<img  onClick={()=>{
   navigate("/");
   setOpen(false)
}} src={sahla_white} alt="" />
</div>
          <div className='close_side_icon'>
            <MenuSwitch checked={open} onChange={(e) => onCloseLiftSide(e)} />
          </div>
          <div className='side_items'>
            {routes?.map((item, index) => {
              return (
                <GlassyBackground
                  solidWhite={location.pathname == item.pathname ? false : true}
                  key={index}
                >
                  <div
                    className={`side_item`}
                    onClick={() => goTo(item.pathname)}
                  >
                    {item.title}
                  </div>
                </GlassyBackground>
              );
            })}
          </div>

        </div>

      </div>
    </>
  );
};

export default LeftSide;
