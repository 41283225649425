

import React from 'react'
import "./style.css"
import Typo from '../Typo/Typo'
import image from "../../assets/images/empty.png"
const EmptyContent = ({text , typoProps , position , size , animated}) => {
  return (
    <div className={` empty_content d-flex flex-column align-items-${position || "center"} justify-content-center`}>
        <div className={`empty_image ${animated ? "t_b_animate":""}`}>
          <img width={size == "medium" ? 150 : size == "large" ? 200 : 100 } src={image} alt="" />
        </div>
        {
          text &&
        <div>
          <Typo py={"50px"} positon={"center"} variant={"h3"} color={"dark1"} {...typoProps}>{text}</Typo>
        </div>
        }
    </div>
  )
}

export default EmptyContent
